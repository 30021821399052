import { Listbox } from "@headlessui/react";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import styled, { css, ThemeProp } from "../styles";
import { SingleCheckBox } from "./Checkbox";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { Text } from "./Text";

export interface DropdownOption {
  label: string;
  value: any;
  disabled?: boolean;
  comingSoon?: boolean;
  annotation?: React.ReactNode;
}

interface Props {
  margin?: string;
  options: DropdownOption[];
  selectedOptions: string[]; // Always an array of strings
  setSelectedOptions: (selected: string[]) => void;
  renderLabel: () => string;
  placeholder?: string;
  disableSelection?: boolean;
  selectionMode: "single" | "multiple";
  dropdownType?: "text" | "actions"; // actions is the 3 dots menu
  disableOptionSort?: boolean;
  width?: number;
  size?: "xs" | "s";
  showSelectAll?: boolean;
  anchor?: "top end";
}

const StyledListboxButton = styled(Listbox.Button)<{
  isDisabled?: boolean;
  dropdownType?: "text" | "actions";
  width?: number;
  size?: "xs" | "s";
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  cursor: pointer;
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-size: ${(p) => p.theme.typography.size.m};
  opacity: ${(p) => (p.isDisabled ? 0.5 : 1)};
  height: ${(p) => (p.size === "s" ? 36 : 42)}px;
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.misc.borderRadius};

  ${(p) =>
    p.dropdownType === "text"
      ? css`
          background: transparent;
          border: none;
          border-radius: none !important;
          color: ${(p) => p.theme.color.primary};
          font-size: ${(p) => p.theme.typography.size.s};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : p.dropdownType === "actions"
      ? css`
          background: transparent;
          border: none;
          color: ${(p) => p.theme.color.typography.secondary};
        `
      : css`
          color: ${(p) => p.theme.color.typography.text};
          width: ${(p: ThemeProp & { width?: number }) =>
            p.width ? `${p.width}px` : "100%"};
          height: ${(p: ThemeProp & { height?: number }) =>
            p.height ? p.height : 52}px;
          background-color: ${(p) => p.theme.color.card.background};
          border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
          &:focus-visible {
            outline: none !important;
          }

          &:focus-within {
            outline: none !important;
          }

          &:hover {
            border-color: ${(p) => p.theme.color.typography.text};
            outline: none;
          }

          &[aria-expanded="true"] {
            border-color: ${(p) => p.theme.color.primary};
            outline: none;
          }

          &:focus,
          &:active {
            border-color: ${(p) => p.theme.color.primary};
            box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
            outline: none;
          }
        `}

  ${(p) =>
    p.size == "xs"
      ? css`
          height: 36px;
          font-size: ${(p) => p.theme.typography.size.s};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
        `
      : p.size == "s"
      ? css`
          height: 42px;
          font-size: ${(p) => p.theme.typography.size.m};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
        `
      : css`
          height: 48px;
          font-size: ${(p) => p.theme.typography.size.m};
          border-radius: ${(p) => p.theme.misc.borderRadius};
        `}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
  }
`;

const StyledListboxOptions = styled(Listbox.Options)<{
  dropdownType?: "text" | "actions";
}>`
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  margin-top: ${(p) => p.theme.spacing.xs};
  max-height: 260px;
  min-width: 200px;
  overflow-y: auto;
  position: absolute;
  padding: 0px;
  z-index: 10;

  ${(p) =>
    p.dropdownType === "text"
      ? css`
          right: 0px !important;
        `
      : css`
          left: 0px;
        `}

  &:focus-visible {
    outline: none !important;
  }
`;

const StyledListboxOption = styled(Listbox.Option)<{ comingSoon?: boolean }>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  cursor: ${(p) => (p.comingSoon ? "not-allowed" : "pointer")};
  opacity: ${(p) => (p.comingSoon ? 0.7 : 1)};
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: ${(p) => p.theme.color.input.hover};
  }

  &:focus-visible {
    outline: none !important;
  }
`;

export function Dropdown({
  options,
  selectedOptions,
  setSelectedOptions,
  renderLabel,
  disableSelection,
  selectionMode,
  dropdownType,
  disableOptionSort,
  width,
  size,
  showSelectAll,
  anchor,
}: Props) {
  const theme = useTheme();

  const handleSelect = (newValue: string | string[]) => {
    if (selectionMode === "multiple" && Array.isArray(newValue)) {
      setSelectedOptions(newValue);
    } else if (selectionMode === "single" && typeof newValue === "string") {
      setSelectedOptions([newValue]); // Wrap single selection in an array
    }
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      // If all are selected, deselect all
      setSelectedOptions([]);
    } else {
      // Otherwise, select all non-disabled and non-coming soon options
      const allValues = options
        .filter((option) => !option.disabled && !option.comingSoon)
        .map((option) => option.value);
      setSelectedOptions(allValues);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Listbox
        as="div"
        value={selectedOptions}
        onChange={handleSelect}
        multiple={selectionMode === "multiple"}
        disabled={disableSelection}
      >
        {({ open }) => (
          <>
            <StyledListboxButton
              isDisabled={disableSelection}
              width={width}
              size={size}
              dropdownType={dropdownType}
              onClick={(e: MouseEvent) => {
                if (!disableSelection) {
                  e.stopPropagation();
                }
              }}
            >
              {dropdownType === "actions" ? (
                <Dots />
              ) : (
                <>
                  <span
                    style={{
                      marginRight: 20,
                      fontSize:
                        size === "s"
                          ? theme.typography.size.s
                          : theme.typography.size.m,
                    }}
                  >
                    {renderLabel()}
                  </span>
                  <span style={{ position: "absolute", right: 0 }}>
                    <Flex margin="0 m 0 0" style={{ marginTop: 2 }}>
                      <Chevron
                        size="s"
                        style={{ marginLeft: "auto" }}
                        direction={open ? "up" : "down"}
                        animateTransition
                        color={
                          dropdownType === "text"
                            ? theme.color.primary
                            : theme.color.typography.secondary
                        }
                      />
                    </Flex>
                  </span>
                </>
              )}
            </StyledListboxButton>
            {open && (
              <StyledListboxOptions
                anchor={anchor}
                style={width && { width: width }}
                onClick={(e: MouseEvent) => e.stopPropagation()}
                dropdownType={dropdownType}
              >
                {showSelectAll && selectionMode === "multiple" && (
                  <SelectAllWrap
                    direction="row"
                    margin="0 s 0 0"
                    onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSelectAll();
                    }}
                  >
                    <Text margin="0" weight="semi" size="s">
                      {selectedOptions.length === options.length
                        ? "Deselect All"
                        : "Select All"}
                    </Text>
                  </SelectAllWrap>
                )}
                {options
                  .sort((a, b) => {
                    if (disableOptionSort) return 0;
                    if (a.comingSoon && !b.comingSoon) return 1;
                    if (!a.comingSoon && b.comingSoon) return -1;
                    return a.label > b.label ? 1 : -1;
                  })
                  .map((option) => (
                    <StyledListboxOption
                      key={option.value}
                      value={option.value}
                      comingSoon={option.comingSoon}
                      onClick={(e: MouseEvent) => {
                        if (option.comingSoon) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                    >
                      {({ selected }) => (
                        <Flex
                          direction="row"
                          margin="0 s 0 0"
                          align="center"
                          justify="space-between"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Flex direction="row" margin="0 s 0 0">
                            {selectionMode === "multiple" ? (
                              <Flex
                                style={{ width: 20 }}
                                margin="0 s 0 0"
                                align="center"
                                justify="center"
                              >
                                <SingleCheckBox checked={selected} size={"s"} />
                              </Flex>
                            ) : null}
                            <Text
                              size={dropdownType === "actions" ? "s" : "m"}
                              weight={
                                dropdownType === "actions" ? "semi" : "normal"
                              }
                              margin="0"
                              truncate
                            >
                              {option.label}
                            </Text>
                          </Flex>
                          {option.comingSoon && (
                            <Text
                              size="xs"
                              margin="0 0 0 m"
                              isInline
                              colorPreset="reward"
                            >
                              Coming Soon
                            </Text>
                          )}
                          {option.annotation}
                        </Flex>
                      )}
                    </StyledListboxOption>
                  ))}
              </StyledListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
}

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.typography.secondary};
  margin: 1px 0px;
`;

function Dots() {
  return (
    <Flex direction="column" align="center" justify="center">
      <Dot />
      <Dot />
      <Dot />
    </Flex>
  );
}

const SelectAllWrap = styled(Flex)`
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.theme.color.typography.secondary}20;

  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  display: flex;
  align-items: center;
  user-select: none;

  :hover {
    background-color: ${(p) => p.theme.color.input.hover};
  }
`;
