import { usePostHog } from "posthog-js/react";
import { useIntercom } from "react-use-intercom";

export default function useAnalytics() {
  const { trackEvent: intercomTrack, update: intercomUpdate } = useIntercom();

  const posthog = usePostHog();

  return {
    track: (name: string, props?: { [key: string]: any }) => {
      intercomTrack(name, props);
      posthog.capture(name, props);
    },
    identifyUser: (userId: string, props?: { [key: string]: any }) => {
      intercomUpdate(props);
      posthog.identify(userId, props);
    },
  };
}
